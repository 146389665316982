import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import MenuOnTop from "../componentes/menus/MenuOnTop";
import { ControllerPrimeiroAcesso } from "./ControllerPrimeiroAcesso";
import InfoContent from "./InfoContent/InfoContent";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import ModalAlertInputs from "./Modals/ModalAlertInputs";
import MenuOnTopMobile from "../componentes/menus/MenuOnTopMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


function ViewsPrimeiroAcesso() {

    const {
        isMobile,
        screenWidth,
        status,
        companyData,
        handleFieldChangeCompany,
        businessAddress,
        handleChangeBusinessAddress,
        partner,
        handleChangePartner,
        bankInfo,
        handleChangeBankInfo,
        moreInfo,
        handleChangeMoreInfo,
        configuracaoPix,
        handleChangeConfiguracaoPix,
        logo,
        handleChangeLogo,
        unitDetails,
        handleChangeUnitDetails,
        addNewPartner,
        correspondenceAddress,
        handleChangeCorrespondenceAddress,
        getSameAdress,
        moreInfoEstablishment,
        handleChangeMoreInfoEstablishment
    } = ControllerPrimeiroAcesso();

    const [step, setStep] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const [modalAlertInputs, setModalAlertInputs] = useState(false);


    const renderStep = () => {
        switch (step) {
            case 0:
                return <Step1
                    status={status}
                    companyData={companyData}
                    screenWidth={screenWidth}
                    handleFieldChangeCompany={handleFieldChangeCompany}
                    businessAddress={businessAddress}
                    handleChangeBusinessAddress={handleChangeBusinessAddress}
                    partner={partner}
                    handleChangePartner={handleChangePartner}
                    addNewPartner={addNewPartner}
                />;
            case 1:
                return <Step2
                    status={status}
                    bankInfo={bankInfo}
                    screenWidth={screenWidth}
                    handleChangeBankInfo={handleChangeBankInfo}
                    moreInfo={moreInfo}
                    handleChangeMoreInfo={handleChangeMoreInfo}
                    configuracaoPix={configuracaoPix}
                    handleChangeConfiguracaoPix={handleChangeConfiguracaoPix}
                />;
            case 2:
                return <Step3
                    status={status}
                    logo={logo}
                    screenWidth={screenWidth}
                    handleChangeLogo={handleChangeLogo}
                    unitDetails={unitDetails}
                    handleChangeUnitDetails={handleChangeUnitDetails}
                    correspondenceAddress={correspondenceAddress}
                    handleChangeCorrespondenceAddress={handleChangeCorrespondenceAddress}
                    getSameAdress={getSameAdress}
                    moreInfoEstablishment={moreInfoEstablishment}
                    handleChangeMoreInfoEstablishment={handleChangeMoreInfoEstablishment}
                />;
            case 3:
                return <Step4 logo={logo} status={status} screenWidth={screenWidth} />;
            default:
                return null;
        }
    };

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated <= min) {
            return min;
        } else if (calculated >= max) {
            return max;
        }
    }

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setStep((prevActiveStep) => prevActiveStep + 1);
    };

    return (
        <>
            <ModalAlertInputs modal={modalAlertInputs} close={() => setModalAlertInputs(false)} />

            {screenWidth <= 950 ?
                <MenuOnTopMobile calcMimMax={calcMimMax} screenWidth={screenWidth} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                :
                <MenuOnTop calcMimMax={calcMimMax} screenWidth={screenWidth} />
            }


            {!menuOpen ?
                <Grid sx={{
                    display: 'flex',
                    ...styles.content,
                    gap: `${screenWidth / 55}px`,
                    backgroundColor: screenWidth <= 950 ? '#FFFFFF' : '#FEFDF9',
                    flexDirection: screenWidth <= 950 ? 'column' : 'row',
                    padding: screenWidth <= 950 ? 'none' : screenWidth <= 1400 ? `${calcMimMax(11, 45, 94)}px ${calcMimMax(11, 100, 94)}px` : `${calcMimMax(11, 45, 32)}px ${calcMimMax(11, 100, 14)}px`,
                }}>
                    <InfoContent
                        step={step}
                        setStep={setStep}
                        setModalAlertInputs={setModalAlertInputs}
                        status={status}
                        screenWidth={screenWidth}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        handleNext={handleNext}
                    />

                    <Grid sx={{
                        width: screenWidth <= 950 ? '100%' : '68%',
                        borderRadius: screenWidth <= 950 ? '30px' : null,
                        padding: screenWidth <= 950 ? '27px 30px' : null
                    }}>
                        {renderStep()}

                        {screenWidth <= 950 && step != 3 ?
                            <Button
                                onClick={handleNext}
                                sx={{
                                    width: '100%',
                                    bgcolor: '#17333A',
                                    textTransform: 'none',
                                    borderRadius: '30px',
                                    marginTop: '25px'
                                }}>
                                <Grid sx={{
                                    width: '100%',
                                    gap: `${calcMimMax(0, 26, 110)}px`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: `${calcMimMax(0, 5, 210)}px ${calcMimMax(0, 13, 215)}px`,
                                    // padding: '5px 13px',
                                }}>
                                    <Typography sx={{
                                        color: '#FEFDF9',
                                        fontSize: calcMimMax(13, 16, 100),
                                        fontWeight: 500,

                                    }}>
                                        Enviar para aprovação | Próximo passo
                                    </Typography>
                                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 14, color: "#FFFFFF" }} />
                                </Grid>
                            </Button> : null}
                    </Grid>


                </Grid>
                : null}


        </>
    )
}

export default ViewsPrimeiroAcesso;

const styles = Stylesheet.create({
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
});