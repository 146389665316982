import { useState } from "react";
import Stylesheet from "reactjs-stylesheet";
import ModalUploadFile from "../Modals/ModalUploadFile";
import { Button, Grid, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";

const Step1 = ({
    status,
    companyData,
    screenWidth,
    handleFieldChangeCompany,
    businessAddress,
    handleChangeBusinessAddress,
    partner,
    handleChangePartner,
    addNewPartner
}) => {

    const [modalSocialMei, setModalSocialMei] = useState(false);
    const [modalComprovanteEndereco, setModalComprovanteEndereco] = useState(false);
    const [modalComprovanteEnderecoPartner, setModalComprovanteEnderecoPartner] = useState(false);
    const [tdPatner, setIdPatner] = useState(0);
    const [modalFotoCNHRGPartner, setModalFotoCNHRGPartner] = useState(false);

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>

            {status[0].error ?
                <Grid sx={{
                    bgcolor: '#F0EFEB',
                    borderRadius: '30px',
                    marginBottom: '30px',
                    border: '3px solid #E57373',
                    padding: '23px 25px 15px 25px'
                }}>
                    <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', marginBottom: '30px', borderRadius: '30px' }}>
                        <Grid sx={{
                            gap: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '15px',
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: '#E57373'
                            }}>
                                <FontAwesomeIcon icon={faClose} style={{ fontSize: 24, color: "#FFFFFF" }} />
                            </Grid>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#142A30'
                            }}>
                                Explicação da inconsistência identificada
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099', paddingBottom: '15px' }}>
                                Sua conta não corresponde à empresa cadastrada e o Client ID não está ativo no sistema da Sicoob. Para resolver esse problema e garantir a aprovação da sua conta, é fundamental cadastrar uma conta bancária em nome da empresa e seguir o processo para gerar um novo Client ID.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                Se precisar de ajuda, entre em contato com o Xuxu, que irá guiá-lo em todas as etapas necessárias.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: '#17333A',
                            '&:hover': { backgroundColor: '#142A30' },
                            '&:active': { backgroundColor: '#142A30' }
                        }}
                    >
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '10px' }}>
                            Reenviar Documentos da Empresa e Sócios
                        </Typography>
                    </Button>
                </Grid> : null}

            <Grid sx={{
                bgcolor: screenWidth <= 950 ? '#FEFDF9' : '#F0EFEB',
                borderRadius: '30px',
                border: status[0].success ? '3px solid #9DE200' : status[0].error ? '3px solid #E57373' : screenWidth <= 950 ? '1px solid #F0EFEB' : null,
            }}>

                <ModalUploadFile
                    modal={modalSocialMei}
                    close={() => setModalSocialMei(false)}
                    file={companyData.constratoSocialInscricaoMEI}
                    setFile={handleFieldChangeCompany}
                    fieldName='constratoSocialInscricaoMEI'
                />

                <ModalUploadFile
                    modal={modalComprovanteEndereco}
                    close={() => setModalComprovanteEndereco(false)}
                    file={businessAddress.comprovanteEndereco}
                    setFile={handleChangeBusinessAddress}
                    fieldName='comprovanteEndereco'
                />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Dados da empresa
                        </Typography>
                        <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                            A seguir você irá preencher todas as informações solicitadas em relação a sua empresa.
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Razão social"
                            value={companyData.razaoSocial}
                            onChange={(e) => handleFieldChangeCompany('razaoSocial', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Nome fantasia"
                            value={companyData.nomeFantasia}
                            onChange={(e) => handleFieldChangeCompany('nomeFantasia', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*CPF/CNPJ"
                            value={companyData.cpfCnpj}
                            onChange={(e) => handleFieldChangeCompany('cpfCnpj', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="Inscrição estadual"
                            value={companyData.inscricaoEstadual}
                            onChange={(e) => handleFieldChangeCompany('inscricaoEstadual', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: '100%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*URL Site"
                            value={companyData.urlSite}
                            onChange={(e) => handleFieldChangeCompany('urlSite', e.target.value)}
                        />
                        <Grid
                            onClick={() => setModalSocialMei(true)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '2px solid #142A3066',
                                borderRadius: '30px',
                                bgcolor: '#FBFBF6',
                                flexBasis: screenWidth <= 950 ? '100%' : '49%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                                cursor: 'pointer'
                            }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '100%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="+ Contrato social / Inscrição MEI"

                                value={companyData.constratoSocialInscricaoMEI?.name ? companyData.constratoSocialInscricaoMEI?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Endereço comercial
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>

                        {screenWidth > 950 ? <Grid sx={{
                            ...styles.customInputNumber,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexBasis: '35%',
                            maxWidth: '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={businessAddress.cep}
                                onChange={(e) => handleChangeBusinessAddress('cep', e.target.value)}
                            />
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={businessAddress.estado}
                                onChange={(e) => handleChangeBusinessAddress('estado', e.target.value)}
                            />
                        </Grid> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={businessAddress.cep}
                                onChange={(e) => handleChangeBusinessAddress('cep', e.target.value)}
                            /> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={businessAddress.estado}
                                onChange={(e) => handleChangeBusinessAddress('estado', e.target.value)}
                            /> : null}

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Endereço"
                            value={businessAddress.endereco}
                            onChange={(e) => handleChangeBusinessAddress('endereco', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Bairro"
                            value={businessAddress.bairro}
                            onChange={(e) => handleChangeBusinessAddress('bairro', e.target.value)}
                        />

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Cidade"
                            value={businessAddress.cidade}
                            onChange={(e) => handleChangeBusinessAddress('cidade', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Complemento"
                            value={businessAddress.complemento}
                            onChange={(e) => handleChangeBusinessAddress('complemento', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="Ponto de referência"
                            value={businessAddress.pontoReferencia}
                            onChange={(e) => handleChangeBusinessAddress('pontoReferencia', e.target.value)}
                        />
                        <Grid
                            onClick={() => setModalComprovanteEndereco(true)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '2px solid #142A3066',
                                borderRadius: '30px',
                                bgcolor: '#FBFBF6',
                                flexBasis: screenWidth <= 950 ? '100%' : '49%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                                cursor: 'pointer'
                            }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '100%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="+Comprovante de endereço"
                                value={businessAddress.comprovanteEndereco?.name ? businessAddress.comprovanteEndereco?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Quadro societário
                        </Typography>
                        <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                            Adicione os dados cadastrais de todos os proprietários do estabelecimento.
                        </Typography>
                    </Grid>

                    {partner.map((i, index) => (
                        <>
                            <ModalUploadFile
                                modal={modalComprovanteEnderecoPartner}
                                close={() => setModalComprovanteEnderecoPartner(false)}
                                file={i.comprovanteEndereco}
                                setFile={handleChangePartner}
                                fieldName='comprovanteEndereco'
                                useId={true}
                                id={index}
                            />

                            <ModalUploadFile
                                modal={modalFotoCNHRGPartner}
                                close={() => setModalFotoCNHRGPartner(false)}
                                file={i.fotoCNHRG}
                                setFile={handleChangePartner}
                                fieldName='fotoCNHRG'
                                useId={true}
                                id={index}
                            />

                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9',
                                padding: '25px',
                                borderRadius: '30px',
                                marginBottom: '15px'
                            }}>

                                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A3099' }}>
                                    Sócio {index < 9 ? '0' + (index + 1) : index + 1}
                                </Typography>

                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '100%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Nome completo"
                                    value={i.nome}
                                    onChange={(e) => handleChangePartner(index, 'nome', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '100%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*E-mail"
                                    value={i.email}
                                    onChange={(e) => handleChangePartner(index, 'email', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*CPF"
                                    value={i.cpf}
                                    onChange={(e) => handleChangePartner(index, 'cpf', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Data nascimento"
                                    value={i.dtNascimento}
                                    onChange={(e) => handleChangePartner(index, 'dtNascimento', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Celular 1 (DDD + N)"
                                    value={i.cell1}
                                    onChange={(e) => handleChangePartner(index, 'cell1', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Celular 2 (DDD + N)"
                                    value={i.cell2}
                                    onChange={(e) => handleChangePartner(index, 'cell2', e.target.value)}
                                />
                                <Grid sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '2px solid #142A3066',
                                    borderRadius: '30px',
                                    bgcolor: '#FBFBF6',
                                    flexBasis: screenWidth <= 950 ? '100%' : '48.9%',
                                    maxWidth: screenWidth <= 950 ? '100%' : '48.9%',
                                }}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputNumber,
                                            flexBasis: '5%',
                                            maxWidth: '49%',
                                            '& .MuiOutlinedInput-input': {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '30px',
                                                border: 'none',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        type="text"
                                        placeholder="+Comprovante de endereço"
                                        onClick={() => setModalComprovanteEnderecoPartner(true)}
                                        value={i.comprovanteEndereco?.name ? i.comprovanteEndereco?.name : ''}
                                    />
                                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                                </Grid>

                                <Grid sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '2px solid #142A3066',
                                    borderRadius: '30px',
                                    bgcolor: '#FBFBF6',
                                    flexBasis: screenWidth <= 950 ? '100%' : '48.9%',
                                    maxWidth: screenWidth <= 950 ? '100%' : '48.9%',
                                }}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputNumber,
                                            flexBasis: '5%',
                                            maxWidth: '49%',
                                            '& .MuiOutlinedInput-input': {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '30px',
                                                border: 'none',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        type="text"
                                        placeholder="+Foto CNH ou RG"
                                        onClick={() => setModalFotoCNHRGPartner(true)}
                                        value={i.fotoCNHRG?.name ? i.fotoCNHRG?.name : ''}
                                    />
                                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                                </Grid>
                            </Grid>
                        </>
                    ))}

                    <Grid
                        onClick={() => addNewPartner()}
                        sx={{
                            gap: '10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9',
                            padding: '20px',
                            borderRadius: '30px',
                            marginY: '15px',
                            cursor: 'pointer'
                        }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A3099' }}>
                            + Adicionar sócio
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    )
}

export default Step1;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '12px',
            bgcolor: '#FEFDF999',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})