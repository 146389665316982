import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck, faClose, faExclamation } from "@fortawesome/free-solid-svg-icons";
import fileIcon from '../../assets/icons/fileIcon.png'
import bagCashIcon from '../../assets/icons/bagCashIcon.png'
import handsIcon from '../../assets/icons/handsIcon.png'
import checkCircleIcon from '../../assets/icons/checkCircleIcon.png'

const InfoContent = ({ step, setStep, setModalAlertInputs, status, screenWidth, activeStep, setActiveStep, handleNext }) => {

    const steps = [
        {
            label: 'Documentos da empresa e sócios',
            description: `Informações legais e comprovações sobre a existência da empresa e seus sócios, necessárias para formalizações e registros.`,
        },
        {
            label: 'Dados financeiros',
            description: 'Informações legais e comprovações sobre a existência da empresa e seus sócios, necessárias para formalizações e registros.',
        },
        {
            label: 'Informações do estabelecimento',
            description: `Dados relacionados à estrutura física, funcionários, funcionamento e identidade visual do estabelecimento.`,
        },
        {
            label: 'Aprovação de conta',
            description: `Agora que preencheu todas as informações do seu negócio, estamos validando tudo e logo traremos o resultado da análise.`,
        },
    ];

    const mobileIcons = [
        { width: '16px', icon: fileIcon },
        { width: '20px', icon: bagCashIcon },
        { width: '20px', icon: handsIcon },
        { width: '20px', icon: checkCircleIcon }
    ]

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;
        s
        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated <= min) {
            return min;
        } else if (calculated >= max) {
            return max;
        }

        return 16;
    }

    // const [activeStep, setActiveStep] = useState(0);

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setStep((prevActiveStep) => prevActiveStep + 1);
    // };

    function VerticalLinearStepper() {
        return (
            <>
                {steps.map((i, index) => (
                    index == activeStep ?
                        <>
                            <Grid sx={{
                                display: 'flex',
                                bgcolor: status[index].success ? '#9DE20033' : status[index].error ? '#F0929233' : '#F0EFEB',
                                border: status[index].success ? '2px solid #9DE200' : status[index].error ? '2px solid #E57373' : null,
                                borderRadius: '30px',
                                flexDirection: 'column'
                            }}>
                                <Grid sx={{ padding: `${calcMimMax(11, 20, 126)}px ${calcMimMax(11, 25, 126)}px`, }}>
                                    <Grid sx={{
                                        gap: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingBottom: '15px',
                                    }}>
                                        <Grid sx={{
                                            width: '30px',
                                            height: '30px',
                                            border: status[index].success ? '1px solid #9DE200' : status[index].error ? '1px solid #E57373' : index == steps.length - 1 ? '1px solid #FFB74D' : '1px solid #9DE200',
                                            borderRadius: '50px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: status[index].success ? '#9DE200' : status[index].error ? '#E57373' : null
                                        }}>
                                            {status[index].success ?
                                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: 17, color: "#17333A" }} /> :
                                                status[index].error ?
                                                    <FontAwesomeIcon icon={faClose} style={{ fontSize: 17, color: "#FFFFFF" }} /> :
                                                    index == steps.length - 1 ?
                                                        <FontAwesomeIcon icon={faExclamation} style={{ fontSize: 17, color: "#FFB74D" }} /> :
                                                        null
                                            }
                                        </Grid>
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#142A3099'
                                        }}>
                                            {i.label}
                                        </Typography>
                                    </Grid>

                                    <Typography sx={{
                                        fontSize: '14px',
                                        color: '#142A3066'
                                    }}>
                                        {i.description}
                                    </Typography>
                                </Grid>

                                {index == steps.length - 1 ?
                                    null
                                    :
                                    <Button
                                        onClick={handleNext}
                                        sx={{
                                            bgcolor: '#A0A9A8',
                                            textTransform: 'none',
                                            borderBottomLeftRadius: '30px',
                                            borderBottomRightRadius: '30px'
                                        }}>
                                        <Grid sx={{
                                            width: '100%',
                                            gap: `${calcMimMax(0, 26, 110)}px`,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: `${calcMimMax(0, 5, 210)}px ${calcMimMax(0, 13, 215)}px`,
                                            // padding: '5px 13px',
                                        }}>
                                            <Typography sx={{
                                                color: '#FEFDF9',
                                                fontSize: calcMimMax(13, 16, 100),
                                                fontWeight: 500,

                                            }}>
                                                Enviar para aprovação | Próximo passo
                                            </Typography>
                                            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 14, color: "#FFFFFF" }} />
                                        </Grid>
                                    </Button>}
                            </Grid >
                        </>
                        :
                        <>
                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                marginLeft: '20px'
                            }}>

                                {index - 1 != activeStep ?
                                    null :
                                    <Grid sx={{
                                        marginLeft: '12px',
                                        width: '6px',
                                        height: '32px',
                                        bgcolor: status[index].success ? '#9DE200' : status[index].error ? '#E57373' : '#DBDDDB'
                                    }} />
                                }

                                <Grid
                                    onClick={() => {
                                        if (index - 1 !== activeStep) {
                                            setActiveStep(index);
                                            setStep(index);
                                        } else {
                                            setModalAlertInputs(true);
                                        }
                                    }}

                                    sx={{
                                        gap: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Grid sx={{
                                        width: '30px',
                                        height: '30px',
                                        bgcolor: status[index].success ? '#9DE200' : status[index].error ? '#E57373' : index - 1 < activeStep ? '#FFB74D' : '#DBDDDB',
                                        borderRadius: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {status[index].success ?
                                            <FontAwesomeIcon icon={faCheck} style={{ fontSize: 17, color: "#17333A" }} /> :
                                            status[index].error ?
                                                <FontAwesomeIcon icon={faClose} style={{ fontSize: 17, color: "#FFFFFF" }} /> :
                                                index - 1 < activeStep ?
                                                    <FontAwesomeIcon icon={faExclamation} style={{ fontSize: 17, color: "#000000" }} />
                                                    : null}
                                    </Grid>
                                    <Typography sx={{
                                        fontSize: calcMimMax(14, 16, 100),
                                        fontWeight: 600,
                                        color: '#142A3099'
                                    }}>
                                        {i.label}
                                    </Typography>
                                </Grid>

                                {index == steps.length - 1
                                    ? null
                                    :
                                    <Grid sx={{
                                        marginLeft: '12px',
                                        width: '6px',
                                        height: '32px',
                                        bgcolor: status[index].success ? '#9DE200' : status[index].error ? '#E57373' : index - 1 < activeStep ? '#FFB74D' : '#DBDDDB'
                                    }} />
                                }

                            </Grid>
                        </>
                ))}

                {status.every((i) => i.success === true) ?
                    <Grid sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        bgcolor: '#17333A',
                        borderRadius: '30px',
                        padding: '20px',
                        marginTop: '15px',

                    }}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700 }}>{'Tudo certo :)'}</Typography>
                        <Button sx={{
                            textTransform: 'none',
                            bgcolor: '#9DE200',
                            borderRadius: '20px',
                            '&:hover': { backgroundColor: '#9DE200' },
                            '&:active': { backgroundColor: '#9DE200' }
                        }}>
                            <Typography sx={{ color: '#142A30', fontSize: '13px', fontWeight: 700 }}>
                                {'Acessar o Xulis Parceiros ->'}
                            </Typography>
                        </Button>
                    </Grid>
                    : null}
            </>
        );
    }

    function MobileSepper() {

        return (
            <Grid sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: '#F0EFEB',
                paddingBottom: '17px',
                borderRadius: '0px 0px 35px 35px',
            }}>

                <Grid sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingBottom: '35px',
                }}>
                    <Typography sx={{
                        fontWeight: 700,
                        color: '#142A30',
                        fontSize: '24px',
                        lineHeight: '25px',
                        paddingTop: '58px',
                        paddingBottom: '5px'
                    }}>
                        Meu primeiro acesso
                    </Typography>
                    <Typography sx={{
                        width: '35ch',
                        textAlign: 'center',
                        color: '#142A3099',
                        fontSize: '13px',
                        lineHeight: 1.1
                    }}>
                        Complete o seu cadastro no portal do Xulis Parceiros e tenha acesso a todas as informações e funcionalidades.
                    </Typography>
                </Grid>

                <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                    {mobileIcons.map((i, index) => (
                        <Grid
                            onClick={() => {
                                if (index - 1 !== activeStep) {
                                    setActiveStep(index);
                                    setStep(index);
                                } else {
                                    setModalAlertInputs(true);
                                }
                            }}
                            sx={{
                                bgcolor:
                                    status[index].success ? '#9DE200' : status[index].error ? '#E57373' :
                                        index < step ? '#FFB74D' : index == step ? '#FFF8EA' : '#FEFDF9',
                                borderRadius: '20px',
                                padding: '15px 34px',
                            }}>
                            <img src={i.icon} style={{ width: i.width, height: '20px' }} />
                        </Grid>))
                    }

                </Grid>
            </Grid >
        )
    }

    return (
        <>
            {screenWidth <= 950 ?
                MobileSepper()
                :
                <Grid sx={{
                    width: '32%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>

                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '35px',
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            color: '#142A30',
                            fontSize: '32px',
                            lineHeight: '25px',
                            paddingBottom: '15px'
                        }}>
                            Meu primeiro acesso
                        </Typography >
                        <Typography sx={{
                            color: '#142A3099',
                            fontSize: '14px',
                            lineHeight: 1.1
                        }}>
                            Complete o seu cadastro no portal do Xulis Parceiros e tenha acesso a todas as informações e funcionalidades.
                        </Typography>
                    </Grid>
                    {VerticalLinearStepper()}
                </Grid>}
        </>
    )
}

export default InfoContent;