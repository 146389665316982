import { Button, Grid, OutlinedInput, Switch, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import cloudUpload from '../../assets/icons/cloudUpload.png';
import close from '../../assets/icons/close.png';
import userLight from '../../assets/icons/userLight.png';
import ModalHorarioComercial from "../Modals/ModalHorarioComercial";
import { useEffect, useState } from "react";
import ModalLojaAlugada from "../Modals/ModalLojaAlugada";
import ModalQtdColaboradores from "../Modals/ModalQtdColaboradores";
import ModalUploadFile from "../Modals/ModalUploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";

const Step3 = ({
    status,
    logo,
    screenWidth,
    handleChangeLogo,
    unitDetails,
    handleChangeUnitDetails,
    correspondenceAddress,
    handleChangeCorrespondenceAddress,
    getSameAdress,
    moreInfoEstablishment,
    handleChangeMoreInfoEstablishment
}) => {

    const [modalHorarioComercial, setModalHorarioComercial] = useState(false);
    const [modalLojaAlugada, setModalLojaAlugada] = useState(false);
    const [modalQtdColaboradores, setModalQtdColaboradores] = useState(false);
    const [modalFotoFachada, setModalFotoFachada] = useState(false);
    const [modalComprovanteEndereco, setModalComprovanteEndereco] = useState(false);

    const [temporariFile, setTemporariFile] = useState({});

    useEffect(() => {
        console.log('temporariFile', temporariFile)
    }, [temporariFile])

    // Manipulador para selecionar o arquivo via clique
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const urlLogo = URL.createObjectURL(file);
            // setTemporariFile(URL.createObjectURL(file)); // Gera URL temporária da imagem
            handleChangeLogo('img', urlLogo)
        }
    };

    const handleClearFile = () => {
        handleChangeLogo('img', '')
    };

    // Função para abrir o seletor de arquivos ao clicar
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };

    return (

        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            {status[2].error ?
                <Grid sx={{
                    bgcolor: '#F0EFEB',
                    borderRadius: '30px',
                    marginBottom: '30px',
                    border: '3px solid #E57373',
                    padding: '46px 50px 30px 50px'
                }}>
                    <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', marginBottom: '30px', borderRadius: '30px' }}>
                        <Grid sx={{
                            gap: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '15px',
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: '#E57373'
                            }}>
                                <FontAwesomeIcon icon={faClose} style={{ fontSize: 24, color: "#FFFFFF" }} />
                            </Grid>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#142A30'
                            }}>
                                Explicação da inconsistência identificada
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099', paddingBottom: '15px' }}>
                                Sua conta não corresponde à empresa cadastrada e o Client ID não está ativo no sistema da Sicoob. Para resolver esse problema e garantir a aprovação da sua conta, é fundamental cadastrar uma conta bancária em nome da empresa e seguir o processo para gerar um novo Client ID.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                Se precisar de ajuda, entre em contato com o Xuxu, que irá guiá-lo em todas as etapas necessárias.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        // onClick={() => { set('instituicao', 'Sicoob'); close() }}
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: '#17333A',
                            '&:hover': { backgroundColor: '#142A30' },
                            '&:active': { backgroundColor: '#142A30' }
                        }}
                    >
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '10px' }}>
                            Reenviar Informações do Estabelecimento
                        </Typography>
                    </Button>
                </Grid> : null}

            <Grid sx={{
                bgcolor:  screenWidth <= 950 ? '#FEFDF9' : '#F0EFEB',
                borderRadius: '30px',
                border: status[0].success ? '3px solid #9DE200' : status[2].error ? '3px solid #E57373' : screenWidth <= 950 ? '1px solid #F0EFEB' : null,
            }}>

                <ModalHorarioComercial
                    modal={modalHorarioComercial}
                    close={() => setModalHorarioComercial(false)}
                    file={unitDetails.horario}
                    setFile={handleChangeUnitDetails}
                    fieldName='horario'
                />

                <ModalLojaAlugada
                    modal={modalLojaAlugada}
                    close={() => setModalLojaAlugada(false)}
                    file={unitDetails.lojaAlugada}
                    setFile={handleChangeUnitDetails}
                    fieldName='lojaAlugada'
                />

                <ModalQtdColaboradores
                    modal={modalQtdColaboradores}
                    close={() => setModalQtdColaboradores(false)}
                    file={unitDetails.qtdColaboradores}
                    setFile={handleChangeUnitDetails}
                    fieldName='qtdColaboradores'
                />

                <ModalUploadFile
                    modal={modalFotoFachada}
                    close={() => setModalFotoFachada(false)}
                    file={unitDetails.fotoFachada}
                    setFile={handleChangeUnitDetails}
                    fieldName='fotoFachada'
                />

                <ModalUploadFile
                    modal={modalComprovanteEndereco}
                    close={() => setModalComprovanteEndereco(false)}
                    file={correspondenceAddress.comprovanteEndereco}
                    setFile={handleChangeCorrespondenceAddress}
                    fieldName='comprovanteEndereco'
                />

                <Grid sx={{ padding: '46px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                        <Grid sx={{ maxWidth: '59%' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                                Logomarca
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                A imagem deve estar no formato PNG e não pode ser maior que 5MB.
                            </Typography>
                        </Grid>

                        <Grid
                            sx={{
                                maxWidth: '120px',
                                height: '80px',
                                bgcolor: '#FFF8EA',
                                borderRadius: '20px',
                                border: '1px solid #000000',
                                position: 'relative'
                            }}>
                            {logo.img == '' ?
                                <img src={cloudUpload}
                                    onClick={openFileDialog}
                                    style={{
                                        width: '19.09px',
                                        height: '12.67px',
                                        margin: '33px 10px 33px 69px',
                                        cursor: 'pointer'
                                    }}
                                />
                                :
                                <Grid sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '20px 0px 11px 30px',
                                    alignItems: 'flex-end'
                                }}>
                                    <img src={cloudUpload}
                                        onClick={openFileDialog}
                                        style={{
                                            width: '19.09px',
                                            height: '12.67px',
                                            cursor: 'pointer',
                                            marginRight: '10px'
                                        }}
                                    />

                                    <Grid sx={{
                                        width: '100%',
                                        padding: '0px 35px 0px 30px',
                                        border: '1px solid #17333A',
                                        margin: '10px 0px',
                                    }} />

                                    <img src={close}
                                        onClick={handleClearFile}
                                        style={{
                                            width: '11.08px',
                                            height: '11.08px',
                                            cursor: 'pointer',
                                            marginRight: '14px'
                                        }}
                                    />
                                </Grid>}

                            {logo.img == '' ? <Grid sx={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '80px',
                                bgcolor: '#FEFDF9',
                                position: 'absolute',
                                right: '38px',
                                top: '0px',
                                border: '1px solid #000000',
                            }}>
                                <img src={userLight}
                                    style={{
                                        width: '36.72px',
                                        height: '36.75px',
                                        margin: '21px'
                                    }}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                />
                            </Grid>
                                :
                                <Grid sx={{
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '80px',
                                    bgcolor: '#FEFDF9',
                                    position: 'absolute',
                                    right: '38px',
                                    top: '0px',
                                    border: '1px solid #000000',
                                    backgroundImage: `url(${logo.img})`,
                                    backgroundSize: 'cover'
                                }}>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                    />
                                </Grid>}
                        </Grid>

                    </Grid>

                    <Grid sx={{
                        opacity: '0.7',
                        borderTopStyle: 'dashed',
                        borderTopWidth: '1.5px',
                        borderTopColor: '#142A3066',
                        marginBottom: '30px'
                    }} />

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Detalhes da unidade
                        </Typography>

                        <Grid sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: '100%',
                            maxWidth: '100%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="*Horário comercial"
                                onClick={() => setModalHorarioComercial(true)}
                                value={unitDetails?.horario?.name ? unitDetails?.horario?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Área construída (m²)"
                            value={unitDetails.areaConstruida}
                            onChange={(e) => handleChangeUnitDetails('areaConstruida', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Link Google Maps"
                            value={unitDetails.linkGoogleMaps}
                            onChange={(e) => handleChangeUnitDetails('linkGoogleMaps', e.target.value)}
                        />

                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: screenWidth <= 950 ? '100%' : '49%',
                            maxWidth: screenWidth <= 950 ? '100%' : '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '49%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="*Loja alugada?"
                                onClick={() => setModalLojaAlugada(true)}
                                value={unitDetails?.lojaAlugada ? unitDetails?.lojaAlugada : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: screenWidth <= 950 ? '100%' : '49%',
                            maxWidth: screenWidth <= 950 ? '100%' : '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '49%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="*Qtd colaboradores"
                                onClick={() => setModalQtdColaboradores(true)}
                                value={unitDetails?.qtdColaboradores.qtd ? unitDetails?.qtdColaboradores.qtd : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: screenWidth <= 950 ? '100%' : '49%',
                            maxWidth: screenWidth <= 950 ? '100%' : '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '100%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="+Foto da fachada"
                                onClick={() => setModalFotoFachada(true)}
                                value={unitDetails.fotoFachada?.name ? unitDetails.fotoFachada?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Endereço correspondência
                        </Typography>
                        <Grid sx={{
                            gap: '17px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A3066' }}>
                                Mesmo do comercial
                            </Typography>
                            <Switch sx={styles.switch} onChange={getSameAdress} />
                        </Grid>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        {screenWidth > 950 ? <Grid sx={{
                            ...styles.customInputNumber,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexBasis: '35%',
                            maxWidth: '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={correspondenceAddress.cep}
                                onChange={(e) => handleChangeCorrespondenceAddress('cep', e.target.value)}
                            />
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={correspondenceAddress.estado}
                                onChange={(e) => handleChangeCorrespondenceAddress('estado', e.target.value)}
                            />
                        </Grid> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={correspondenceAddress.cep}
                                onChange={(e) => handleChangeCorrespondenceAddress('cep', e.target.value)}
                            /> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={correspondenceAddress.estado}
                                onChange={(e) => handleChangeCorrespondenceAddress('estado', e.target.value)}
                            /> : null}

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Endereço"
                            value={correspondenceAddress.endereco}
                            onChange={(e) => handleChangeCorrespondenceAddress('endereco', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Bairro"
                            value={correspondenceAddress.bairro}
                            onChange={(e) => handleChangeCorrespondenceAddress('bairro', e.target.value)}
                        />

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Cidade"
                            value={correspondenceAddress.cidade}
                            onChange={(e) => handleChangeCorrespondenceAddress('cidade', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Complemento"
                            value={correspondenceAddress.complemento}
                            onChange={(e) => handleChangeCorrespondenceAddress('complemento', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="Ponto de referência"
                            value={correspondenceAddress.pontoReferencia}
                            onChange={(e) => handleChangeCorrespondenceAddress('pontoReferencia', e.target.value)}
                        />
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: screenWidth <= 950 ? '100%' : '49%',
                            maxWidth: screenWidth <= 950 ? '100%' : '49%'
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '100%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="+Comprovante de endereço"
                                onClick={() => setModalComprovanteEndereco(true)}
                                value={correspondenceAddress.comprovanteEndereco?.name ? correspondenceAddress.comprovanteEndereco?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Mais informações
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: '5%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*Telefone para contato do estabeleciomento"
                            value={moreInfoEstablishment.info}
                            onChange={(e) => handleChangeMoreInfoEstablishment('info', e.target.value)}
                        />
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Step3;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '12px',
            bgcolor: '#FEFDF999',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    switch: {
        width: 31,
        height: 16,
        padding: 0,
        display: 'flex',
        '& .MuiSwitch-switchBase': {
            padding: '2px',
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#7FA80033',
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#7FA800',
                },
            },
            '&.Mui-disabled': {
                '& + .MuiSwitch-track': {
                    backgroundColor: '#2F465040',
                },
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#2F465040',
            borderRadius: 13,
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            backgroundColor: '#17333A',
        },
    }
})